import React from "react"

import { Redirect, withRouter } from "react-router-dom"
import { Form, Input, Button, Tooltip, message } from 'antd'
import { MailOutlined, InfoCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify"

import LoginBase from './LoginBase'

import { connect } from "react-redux"
import { allState as mapStateToProps, allDispatch as mapDispatchToProps } from "../../redux/Selectors"

const queryString = require('query-string');

class ConfirmEmail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    form = React.createRef()

    async onSubmit(values) {
        this.setState({ loading: true })
        Auth.confirmSignUp(
            values.email,
            values.confirmation_code
        )
            .then(data => {
                message.success("Email successfully verified")
                this.props.history.push('/login?' + queryString.stringify({ email: values.email }))
                this.setState({ loading: false })
            })
            .catch(error => {
                message.error(error.message)
                console.log(error)
                this.setState({ loading: false })
            })
    }

    async onSend(e) {
        e.preventDefault()
        this.form.current.validateFields(["email"])
        if (this.form.current.getFieldError("email") === undefined) {
            Auth.resendSignUp(
                this.props.form.getFieldValue("email")
            )
                .then(data => {
                    message.success("Code resent successfully")
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    render() {
        const parsed = queryString.parse(this.props.location.search)
        let email = "email" in parsed ? parsed.email : ""

        if (this.props.login.fechingStatus === true) {
            return null;
        }
        else if (this.props.login.calling === false && this.props.login.auth === true) {
            return <Redirect to="/" />;
        }
        else {
            return (
                <LoginBase title="Verify email">
                    <Form
                        onFinish={(values) => this.onSubmit(values)}
                        hideRequiredMark={true}
                        style={{ margin: "10px" }}
                        ref={this.form}
                    >
                        <Form.Item
                            name='email'
                            rules={
                                [
                                    {
                                        type: 'email',
                                        message: 'Valid email required',
                                    },
                                    {
                                        required: true,
                                        message: 'Email required',
                                    },
                                ]
                            }
                            initialValue={email}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="E-mail"
                                suffix={
                                    <Tooltip title="Fill with your email">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="email"
                                autoFocus={email === ""}
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmation_code"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Verification code required',
                                    },
                                ]
                            }
                        >
                            <Input
                                prefix={<UnlockOutlined />}
                                placeholder="Verification code"
                                suffix={
                                    <Tooltip title="Fill with the code sent to your email">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="off"
                                autoFocus={email !== ""}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button.Group>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.loading}
                                >
                                    Confirm email
                                </Button>
                                <Button
                                    onClick={(e) => this.onSend(e)}
                                    htmlType="submit"
                                >
                                    Resend code
                                </Button>
                            </Button.Group>
                        </Form.Item>
                    </Form>
                </LoginBase>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmEmail));
