import React from "react"

import { Form, Input, Button, message, Card } from 'antd'
import { UnlockOutlined, LockOutlined } from '@ant-design/icons';
import { withRouter  } from "react-router-dom"
import { Auth } from "aws-amplify"

import Base from './Base'

class ChangePassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    onSubmit(values) {
        this.setState({ loading: true })
        Auth.currentAuthenticatedUser()
            .then(user => {
                Auth.changePassword(user, values.current_password, values.new_password)
                    .then(response => {
                        message.success("Password changed successfully")
                        this.props.history.push("/")
                    })
                    .catch(err => {
                        message.error("Error changing password")
                        console.log(err)
                        this.setState({ loading: false })
                    })
            })
            .catch(err => {
                message.error("Error changing password")
                console.log(err)
                this.setState({ loading: false })
            })
    }

    form = React.createRef()

    compareToFirstPassword = (rule, value) => {
        if (value && value !== this.form.current.getFieldValue('new_password')) {
            return Promise.reject('Passwords do not match');
        } else {
            return Promise.resolve()
        }
    };

    validateToNextPassword = (rule, value) => {
        if (value) {
            this.form.current.validateFields(['confirm_password'], { force: true });
        }
        return Promise.resolve()
    };

    render() {
        return (
            <Base
                title="Change password"
            >
                <Card>
                    <Form
                        onFinish={(values) => this.onSubmit(values)}
                        hideRequiredMark={true}
                        style={{ margin: "10px" }}
                        ref={this.form}
                    >
                        <Form.Item
                            name='current_password'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Current password required',
                                    },
                                ]
                            }
                        >
                            <Input.Password
                                prefix={<UnlockOutlined />}
                                placeholder="Current password"
                                type="password"
                                autoComplete="current-password"
                                autoFocus
                            />
                        </Form.Item>
                        <Form.Item
                            name='new_password'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'New password required',
                                    },
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            }
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="New password"
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item
                            name='confirm_password'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Password confirm required',
                                    },
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ]
                            }
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="Password confirm"
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.loading}
                            >
                                Change password
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Base>
        )
    }
}

export default withRouter(ChangePassword)