export const eula = `AI Materials License Agreement

In order to access the Materials (as defined below), you (as defined below) must first agree to this AI Materials License Agreement (“Agreement”). You may not use Facebook’s commissioned deep fake videos (in raw and modified forms), including labels and descriptions, made available by Facebook in conjunction with this Agreement (collectively, “Materials”) if you do not accept this Agreement. By clicking to “Accept” this Agreement, and/or accessing the Materials  you hereby agree to the terms of the Agreement. If you are agreeing to be bound by the Agreement on behalf of your employer or other entity, you represent and warrant to Facebook that you have full legal authority to bind your employer or such entity to this Agreement. If you do not have the requisite authority, you may not accept the Agreement or access the Materials on behalf of your employer or other entity. 
This Agreement is effective upon the earlier of the date that you first access the Materials or accept this Agreement (“Effective Date”), and is entered into by and between Facebook, Inc. (“Facebook”), and you, or your employer or other entity (if you are entering into this agreement on behalf of your employer or other entity) (“You”).

1. Subject to Your compliance with the terms and conditions of this Agreement, Facebook hereby grants to You, a limited, non-exclusive, non-transferable, non-sublicensable license to use the Materials to research, develop and improve software, algorithms, machine learning models, techniques and technologies designed to: (a) detect manipulated media, images, audio and videos; and (b) aid in computer vision research and development.

2. Subject to Your compliance with the terms and conditions of this Agreement, You retain its intellectual property rights in and to all algorithms, software, machine learning models, techniques and technologies, developed or otherwise derived by You from the use of the Materials. Such algorithms, software, machine learning models, techniques and technologies may be used for academic and commercial purposes.

3. As between Facebook and You, Facebook retains all intellectual property rights in and to the Materials. All rights not expressly granted under this Agreement by Facebook are reserved.

4. At any time, Facebook may require You to delete all copies of the Materials (in whole or in part) in Your possession and control. You will promptly comply with any and all such requests. Upon Facebook’s request, You shall provide Facebook with written confirmation of Your compliance with such requirement.

5. If Facebook reasonably believes (as determined at Facebook’s sole discretion) that you are or are likely to be in violation of the terms of this Agreement, Facebook or Facebook’s designee (at Facebook’s sole expense) may audit your use, storage and distribution of the Materials, including, without limitation, any and all records and files associated with the Materials and this Agreement.  You hereby agree to promptly cooperate with such audit.

6. You will not:
    A. modify, translate, or create any derivative works based upon the Materials;
    B. distribute, copy, disclose, assign, sublicense, embed, host or otherwise transfer the Materials to any third party
    C. remove or alter any copyright, trademark or other proprietary notices appearing on or in copies of the Materials;
    D. use the Materials in a pornographic, defamatory or any unlawful manner, or in violation of any applicable regulations or laws;
    E. incorporate the Materials into any other program, dataset, or product;
    F. use the Materials to distribute manipulated images or videos; or
    G. use the Materials for biometric research, development, commercialization or similar purposes; or
    H. use the Materials for any purpose other than that expressly specified in Section 1 of this Agreement.

7. If you use the Materials (or any portion thereof) in a manner that features models or property in connection with a subject that would be unflattering or unduly controversial to a reasonable person, you must indicate: (1) that the content is being used for illustrative purposes only, and (2) any person depicted in the content is a model.

8. Facebook always appreciates your feedback and other suggestions about the Materials. However, you should know and you hereby agree that we may use your feedback and suggestions without any restriction or obligation, including, without limitation, to compensate you or to keep them confidential.

9. THE MATERIALS ARE PROVIDED “AS IS” WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND, INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE.
`