import React from "react"

import { Typography, Card } from 'antd'

import Base from './Base'
import config from '../../util/config'

const { Paragraph, Title, Text } = Typography

export class DataPolicy extends React.Component {
    render() {
        return (
            <Base
                title="DFDC.AI - Data Policy"
                subtitle={"Last updated: " + config.date}
            >
                <Card>
                    <Paragraph>
                        The Deepfake Detection Challenge is a collaboration between Facebook, the Partnership on AI, Microsoft,
                        Amazon and academics from Cornell Tech, MIT, University of Oxford, UC Berkeley, University of Maryland,
                        College Park, and University of Albany-SUNY. The aim of the Deepfake Detection Challenge was to invite
                        people around the world to help build better detection tools to combat misinformation generated by new
                        technologies such as deepfakes, where realistic AI-generated videos are able to show real people doing
                        and saying fictional things.
                    </Paragraph>
                    <Paragraph>
                        The first iteration of the Deepfake Detection Challenge took place in 2019 and invited individuals or
                        teams to create new ways of detecting and preventing tampered media. The original dataset used to create
                        the Deepfake Detection Challenge dataset, as well as the Deepfake Detection Challenge dataset (the <Text strong>"Datasets"</Text>),
                        are now accessible through DFDC.AI (the <Text strong>“Website”</Text>). This Data Policy describes Facebook, Inc.’s
                        (together, <Text strong>“Facebook,” “us,” “our”</Text>) practices for handling your information collected in connection with the Website.
                        The data controller responsible for the personal data of individuals in the European Union is Facebook Ireland Limited.
                    </Paragraph>


                    <Title level={3}>Collection of Information</Title>
                    <Paragraph>
                        When you interact with us through the Website, we may collect or receive the following types of information from you:
                    </Paragraph>


                    <Title level={4}>Information you provide directly</Title>
                    <ul>
                        <li>
                            <Text underline>AWS Account Information:</Text> In order to download the Datasets, you will need to have a valid account with a
                            third party service provider, Amazon Web Services (<Text strong>"AWS"</Text>), since the Datasets is stored on the AWS platform.
                            If you do not already have an AWS account, you will be asked to provide information such as your email address,
                            account name and password. The creation and use of an AWS account is subject to the AWS
                            <a href="https://aws.amazon.com/terms/" rel="noopener noreferrer" target="_blank"> Terms of Use </a>
                            and <a href="https://aws.amazon.com/privacy/" rel="noopener noreferrer" target="_blank"> Privacy Policy. </a>For more information on AWS' data practices,
                            you should consult their <a href="https://aws.amazon.com/privacy/" rel="noopener noreferrer" target="_blank">Privacy Policy.</a>
                        </li>
                        <li>
                            <Text underline>Before Downloading the Datasets:</Text> In addition to the above, we will ask you to provide
                            your full name, an email address, address, organization/affiliation,
                            and intended usage of the data (i.e. an option from a drop down menu for you to select).
                            We also ask that you agree to a license agreement as a condition to access the Datasets.
                        </li>
                    </ul>


                    <Title level={4}>How We Use Information</Title>
                    <Paragraph>
                        We will use the information described above for the purposes of operating and administering the Website and the Datasets.
                        Specifically, we will use the following information for the following purposes:
                    </Paragraph>
                    <ul>
                        <li>
                            <Text underline>Account Administration:</Text> We will use the information you provide to determine your eligibility
                            to and provide you access to the Datasets.
                        </li>
                        <li>
                            <Text underline>To fulfill or meet the reason for which the information is provided:</Text> We collect your information
                            as part of your registration prior to your download of the Datasets for our business records.
                        </li>
                        <li>
                            <Text underline>Communicate with you:</Text> We will use your email address to contact you about the Datasets,
                            including updates and other information you have requested.
                        </li>
                        <li>
                            <Text underline>Preventing fraud and abuse:</Text> We will use the information you provide in order to enforce the
                            Terms of Use (e.g. ensuring eligibility of access and preventing fraud and abuse in access and use of the Datasets),
                            and to prevent and address fraud and other harmful or illegal activity.
                        </li>
                        <li>
                            <Text underline>Research and innovation:</Text> We may use the information you provide in order to further
                            understanding who is accessing and using the Datasets, and for what purposes, to evaluate the benefits of the Datasets.
                        </li>
                    </ul>



                    <Title level={4}>How We Share Information</Title>
                    <Paragraph>
                        There are certain circumstances in which we will share your information with certain third parties without further notice
                        to you, as follows:
                    </Paragraph>
                    <ul>
                        <li>
                            <Text underline>Service Providers & Vendors:</Text> We share information with third-party vendors and service providers
                            who support the Website, for example by providing technical infrastructure services. We also share information with
                            third-party vendors and service providers to help us determine your eligibility to access the Datasets.
                        </li>
                        <li>
                            <Text underline>Legal purposes:</Text> We may share information you provide through the Website for legal purposes,
                            such as responding to subpoenas, court orders, legal process, law enforcement requests, legal claims or government inquiries,
                            detect fraud, and to protect and defend the rights, interests, safety, and security of the Datasets and the Website, our affiliates,
                            owner, users, or the public.
                        </li>
                        <li>
                            <Text underline>Business transfers:</Text> If the ownership or control of Facebook, the Datasets and/or the Website changes,
                            we may transfer your information to the new owner.
                        </li>
                    </ul>


                    <Title level={4}>Your Rights</Title>
                    <Paragraph>
                        In accordance with applicable laws, you may have the right to access, rectify, port and erase your information, as well as the right
                        to restrict and object to certain processing of your information.
                    </Paragraph>
                    <Paragraph>
                        To exercise such rights in relation to the information you provided to us for the Website, please email supportDFDC@fb.com from the
                        email address you provided to create your account on the Website.
                    </Paragraph>


                    <Title level={4}>How We Protect Information</Title>
                    <Paragraph>
                        We take measures to help protect information from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction.
                        However, no data storage system or transmission of data over the Internet or any other public network can be guaranteed
                        to be 100 percent secure.
                    </Paragraph>


                    <Title level={4}>Third Party Links</Title>
                    <Paragraph>
                        Our website may contain links to other sites that we do not own or operate (e.g. AWS). Except as provided herein, we do not provide
                        your personal information to these third parties without your consent. The linked websites have separate and independent
                        rivacy statements, notices and terms of use, which we recommend you carefully review. We do not have any control over such websites,
                        and therefore have no liability or responsibility for the linked websites’ personal information practices.
                    </Paragraph>


                    <Title level={4}>Data Retention</Title>
                    <Paragraph>
                        We retain information relating to the Website until it is no longer necessary to serve the purposes for which it was collected,
                        e.g. to provide access to the Datasets. Reasons we may retain some data for longer periods include security, fraud & abuse
                        prevention, record keeping, complying with legal or regulatory requirements and ensuring the continuity of our services.
                    </Paragraph>


                    <Title level={4}>Participation age</Title>
                    <Paragraph>
                        You must be at least eighteen (18) years old (or over the age of majority in your jurisdiction of residence) in order to create
                        an account on the Website and be eligible to access the Datasets. We will delete any information we may have inadvertently
                        received from anyone under that age upon notice.
                    </Paragraph>


                    <Title level={4}>Questions</Title>
                    <Paragraph>
                        If you have any questions about this Data Policy or our practices, please contact us online or by mail at:
                    </Paragraph>
                    <Paragraph>
                        Facebook, Inc.<br />
                        ATTN: Privacy Operations<br />
                        1601 Willow Road<br />
                        Menlo Park, CA 94025<br />
                    </Paragraph>




                    <Title level={3}>ADDITIONAL INFORMATION FOR CALIFORNIA RESIDENTS</Title>

                    <Title level={4}>Information we collect and sources of information</Title>
                    <Paragraph>
                        Our Data Policy above already describes the information we collect and its sources. This section organizes that
                        information around the personal information categories set forth in the California Consumer Privacy Act (CCPA):
                    </Paragraph>
                    <ul>
                        <li>
                            <Text underline>Identifiers:</Text> This includes for example your real name, alias, postal address,
                            unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other
                            similar identifiers. We obtain this information when you provide it to us or otherwise automatically in the course of
                            your interactions with our website.
                        </li>
                        <li>
                            <Text underline>Internet or other electronic network activity information:</Text> This includes for example information
                            regarding your interactions with our website. We obtain this information automatically in the course of your interactions
                            with our website.
                        </li>
                        <li>
                            <Text underline>Professional or Employment-Related Information:</Text> This includes for example your affiliation with
                            an educational institution. We obtain this information directly from you.
                        </li>
                    </ul>


                    <Title level={4}>How we share personal information</Title>
                    <Paragraph>
                        We do not sell your personal information, but we share information to support our own operational purposes in providing the
                        Website to you, as described in our Data Policy above. For example, we share information with our partners, service providers
                        and related companies to secure and improve our website (such as to identify bugs, repair errors or ensure that services function
                        as intended) or to have them perform services (e.g. hosting the Datasets).
                    </Paragraph>


                    <Title level={4}>How can you exercise your rights provided under the CCPA?</Title>
                    <Paragraph>
                        Under the CCPA, California residents have the following rights:
                    </Paragraph>
                    <ul>
                        <li>
                            <Text underline>Right to Know:</Text> You have the right to request that we disclose to you the personal information we collect,
                            use, or disclose over the past 12 months, and information about our data practices;
                        </li>
                        <li>
                            <Text underline>Right to Request Deletion:</Text> You have the right to request that we delete your personal information
                            that we have collected from you;
                        </li>
                        <li>
                            <Text underline>Right to Non-Discrimination:</Text> We will not discriminate against you for exercising any of these rights.
                        </li>
                        <li>
                            <Text underline>Right to Opt-Out of Sales:</Text> We do not sell personal information, so we do not have an opt-out.
                        </li>
                    </ul>
                    <Paragraph>
                        Please note that to protect your information, we may need to verify your identity before processing your request. In some cases,
                        we may need to collect additional information to verify your identity, such as a government issued ID.
                    </Paragraph>
                    <Paragraph>
                        Under the CCPA, you may exercise these rights yourself or you may designate an authorized agent to make these requests on your
                        behalf. We may request that your authorized agent have written permission from you to make requests on your behalf and may
                        need to verify your authorized agent’s identity.
                    </Paragraph>
                    <Paragraph>
                        To exercise your rights under the CCPA, or if you are an agent authorized to exercise a right under the CCPA, you can
                        email supportDFDC@fb.com or contact us by mail at the address provided above.
                    </Paragraph>



                    <Title level={3}>ADDITIONAL INFORMATION FOR INDIVIDUALS IN THE EUROPEAN UNION</Title>

                    <Title level={4}>What is our legal basis for processing data?</Title>
                    <Paragraph>
                        We collect, use and share data on the basis of the following:
                    </Paragraph>
                    <ul>
                        <li>
                            <Text underline>Contractual necessity:</Text> The majority of the processing of personal data described in this Data Policy
                            is justified on the basis that it is necessary for the performance of a contract and specifically, for the purposes of the
                            Website's Terms of Use {config.link}. For example, we rely on this legal basis to create your account, grant you access to
                            the Datasets and to communicate and issue communications in relation to the Datasets.
                        </li>
                        <li>
                            The other legal bases that we rely on in certain instances when processing your data are:
                        </li>
                        <li>
                            <Text underline>Legitimate interests:</Text> Our legitimate interests or the legitimate interests of a third party,
                            where not outweighed by your interests or fundamental rights and freedoms. We rely on this legal basis to share information
                            with others, including law enforcement, and to respond to legal requests. The legitimate interests we rely on for this
                            processing are: to prevent and address fraud, unauthorized use of the Website or the Datasets, breaches of our terms and
                            policies, or other harmful or illegal activity; to protect ourselves (including our rights, property or products), our users
                            or others, including as part of investigations or regulatory enquiries; or to prevent death or imminent bodily harm.
                        </li>
                        <li>
                            <Text underline>Compliance with a legal obligation:</Text> We rely on this legal basis for processing data when the
                            law requires it, including, for example, if there is a valid legal request for certain data.
                        </li>
                    </ul>
                    <Paragraph>
                        When we process your data as necessary for the purposes of legitimate interests, you have the right to object to, and seek
                        restriction of, our processing. To exercise your rights, you can email supportDFDC@fb.com. In evaluating an objection, we will
                        evaluate several factors, including: reasonable user expectations; the benefits and risks to you and third parties; and other available
                        means to achieve the same purpose that may be less invasive and do not require disproportional effort. Your objection will be upheld and
                        we will cease processing your information, unless the processing is based on compelling legitimate grounds or is needed for legal reasons.
                    </Paragraph>


                    <Title level={4}>Data Transfers</Title>
                    <Paragraph>
                        We transfer personal data both internally within the Facebook Companies and externally with our partners, which include Amazon
                        Web Services (<Text strong>“AWS”</Text>). Information controlled by Facebook Ireland Limited may be transferred or transmitted to, or stored and
                        processed in, the United States or other countries for the purposes described in this policy. These data transfers are necessary
                        to provide the services set forth in the Website's Terms of Use {config.link}. We utilize standard contract clauses approved by
                        the European Commission and rely on the European Commission’s adequacy decisions about certain countries, as applicable, for
                        data transfers from the EEA to the United States and other countries.
                    </Paragraph>

                    <Title level={4}>Contact Information for individuals in the European Union only</Title>
                    <Paragraph>
                        Individuals in the European Union is Facebook Ireland Limited, which you can contact online, or by mail at:
                    </Paragraph>
                    <Paragraph>
                        Facebook Ireland Ltd.<br />
                        4 Grand Canal Square Grand<br />
                        Canal Harbour Dublin 2 Ireland<br />
                    </Paragraph>
                    <Paragraph>
                        <a href="https://www.facebook.com/help/contact/540977946302970" rel="noopener noreferrer" target="_blank">Contact the Data Protection Officer </a>
                        for Facebook Ireland Ltd.
                    </Paragraph>
                    <Paragraph>
                        You also have the right to lodge a complaint with Facebook Ireland’s lead supervisory authority, the Irish Data Protection Commissioner,
                        or your local supervisory authority.
                    </Paragraph>
                </Card>
            </Base>
        )
    }
}