import React from "react"

import { Typography, Card } from 'antd'

import Base from './Base'
import config from '../../util/config'

const { Paragraph, Title, Text } = Typography

export class ToS extends React.Component {
    render() {
        return (
            <Base
                title="DFDC.AI - Terms of Use"
                subtitle={"Last updated: " + config.date}
            >
                <Card>
                    <Paragraph>
                        The Deepfake Detection Challenge (<a href="https://deepfakedetectionchallenge.ai/" rel="noopener noreferrer" target="_blank">https://deepfakedetectionchallenge.ai/</a>)
                        was a collaboration between Facebook,
                        the Partnership on AI, Microsoft, Amazon and academics from Cornell Tech, MIT, University of Oxford, UC Berkeley,
                        University of Maryland, College Park, and University of Albany-SUNY. The aim of the Deepfake Detection Challenge
                        was to invite people around the world to help build better detection tools to combat misinformation generated
                        by new technologies such as deepfakes, where realistic AI-generated videos are able to show real people doing
                        and saying fictional things.
                    </Paragraph>
                    <Paragraph>
                        The first iteration of the Deepfake Detection Challenge took place in 2019 and invited individuals or teams to create new ways of
                        detecting and preventing tampered media. The original dataset used to create the Deepfake Detection Challenge dataset, as
                        well as the Deepfake Detection Challenge dataset (the <Text strong>"Datasets"</Text>), are now accessible through DFDC.AI (the <Text strong>“Website”</Text>).
                    </Paragraph>
                    <Paragraph>
                        These Terms of Use (<Text strong>“Terms”</Text>) govern the use of the Website and constitute an agreement between you and Facebook, Inc. and Facebook Companies
                        (<Text strong>“Facebook,” “us,” “our”</Text>), so it is important that you review them carefully.
                    </Paragraph>
                    <Paragraph>
                        By accessing the Website, you accept and agree to be bound by these Terms as well as the Data Policy [add link] and [by downloading the Datasets, you
                        also hereby agree and accept to be bound by the datasets’ licence agreement (<Text strong>"Datasets License"</Text>).
                    </Paragraph>

                    <Title level={3}>Your Commitments</Title>
                    <Paragraph>
                        We administer the Website and the Datasets, and in exchange for accessing the Website and the Datasets, we ask to confirm that:
                    </Paragraph>
                    <ul>
                        <li>
                            you are at least eighteen (18) years old (or over the age of majority in your jurisdiction of residence); and
                        </li>
                        <li>
                            if you are agreeing to be bound by these Terms and the Datasets Licence on behalf of your employer or other entity, you represent and warrant to
                            Facebook that you have full legal authority to bind your employer or such entity to these Terms and the Datasets Licence.
                        </li>
                    </ul>

                    <Title level={3}>Prohibited Conduct</Title>
                    <Paragraph>
                        You may not access or use, or attempt to access or use, the Website nor the Datasets to take any action that could harm us or any
                        third party, interfere with the operation of the Website, or use the Website or the Datasets in a manner that violates
                        any applicable laws or regulations. For example, and without limitation, you may not:
                    </Paragraph>
                    <ul>
                        <li>
                            do or share anything that breaches these Terms or other applicable terms;
                        </li>
                        <li>
                            upload viruses or malicious code or do anything that could disable,
                            overburden, or impair the proper working or appearance of the Website, the Datasets or generally our services or products;
                        </li>
                        <li>
                            access or collect data from the Website or the Datasets using automated means
                            (without our prior permission) or attempt to access data you do not have permission to access;
                        </li>
                        <li>
                            submit content that infringes on the copyright, trademark, patent, or other intellectual property
                            rights of any third party, are false, misleading, libelous, defamatory, obscene, abusive, hateful, or
                            sexually-explicit, violate a third party’s right to privacy or publicity, or degrade others on the basis of
                            gender, race, class, ethnicity, national origin, religion, sexual preference, disability, or other classification; and
                        </li>
                        <li>
                            engage in any other conduct that restricts or inhibits any person from using or enjoying the Website or the Datasets, or that, in our
                            sole judgment, exposes us or any of our users, affiliates, or any other third party to any liability, damages, or detriment of any type.
                        </li>
                    </ul>


                    <Title level={3}>Intellectual Property Rights</Title>
                    <Paragraph>
                        All aspects of the Datasets are solely owned by or licensed to, Facebook. Your use of the Datasets is strictly subject to the terms of
                        the Datasets License. Except as otherwise stated in the Datasets Licence, you do not acquire any rights over such Datasets.
                    </Paragraph>


                    <Title level={3}>Copyright</Title>
                    <Paragraph>
                        The Website is protected under copyright law in the United States. All copyrights in the Website are owned by us or our
                        third-party licensors. You may not publish, reproduce, distribute, display, perform, edit, adapt, modify, or otherwise exploit
                        any part of the Website without our written consent. We respect intellectual property rights. If you believe in good faith that
                        your work has been reproduced or is accessible on the Website in a way that constitutes copyright infringement, please provide
                        our designated agent with the following information in writing:
                    </Paragraph>
                    <ul>
                        <li>
                            identification of the copyrighted work or a representative list of copyrighted works claimed to have been infringed;
                        </li>
                        <li>
                            identification of the allegedly infringing material and information reasonably sufficient to permit us to locate the material;
                        </li>
                        <li>
                            your name, address and a daytime telephone number, and an email address if available, so that we may contact you if necessary;
                        </li>
                        <li>
                            a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is
                            authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
                        </li>
                        <li>
                            a statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by
                            the copyright owner, its agent, or the law; and
                        </li>
                        <li>
                            a physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right
                            that is allegedly infringed.
                        </li>
                    </ul>
                    <Paragraph>
                        You can reach our designated agent by contacting:
                    </Paragraph>
                    <Paragraph>
                        Facebook Designated Agent, Facebook, Inc.<br />
                        1601 Willow Road<br />
                        Menlo Park, CA 94025<br />
                        (650) 543-4800<br />
                        ip@fb.com<br />
                    </Paragraph>
                    <Paragraph>
                        Upon receipt of a valid notice of claimed infringement (or any statement in conformance with 17 U.S.C. § 512(c)(3)), we will
                        expeditiously remove or disable access to the allegedly infringing content. We will terminate the privileges of users who
                        repeatedly infringe copyright.
                    </Paragraph>


                    <Title level={3}>Limitation of Liabilities</Title>
                    <Paragraph>
                        The Website and the Datasets are provided “as is” without warranties of any kind, either express or implied, including,
                        without limitation, warranties of merchantability, fitness for a particular purpose, title, non-infringement, or other
                        violation of rights. We do not warrant the adequacy, currency, accuracy, likely results, or completeness of the Website,
                        the Datasets or any third-party sites linked to or from the Website, or that the functions provided will be uninterrupted,
                        virus, or error-free. We expressly disclaim any liability for any errors or omissions in the content included on the Website,
                        the Datasets or any third party sites linked to or from the Website. Some jurisdictions may not allow the exclusion of implied
                        warranties, so some of the above exclusions may not apply to you.
                    </Paragraph>


                    <Title level={3}>Disputes</Title>
                    <Paragraph>
                        You agree that your access to and use of the Website will be governed by and will be construed in accordance with the laws 
                        of the State of California without regard to principles of conflicts of laws. For any claim, cause of action, or dispute 
                        you have against us that arises out of or relates to these Terms, you agree that it will be resolved exclusively in the U.S. 
                        District Court for the Northern District of California or a state court located in San Mateo County.
                    </Paragraph>
                </Card>
            </Base>
        )
    }
}