import React from "react"

import { Redirect, withRouter, Link } from "react-router-dom"
import { Form, Input, Button, Tooltip, message, Space, Divider } from 'antd'
import { LockOutlined, InfoCircleOutlined, MailOutlined } from '@ant-design/icons'
import { Auth } from "aws-amplify"

import LoginBase from './LoginBase'

import { connect } from "react-redux"
import { allState as mapStateToProps, allDispatch as mapDispatchToProps } from "../../redux/Selectors"

const queryString = require('query-string');

class Login extends React.Component {

    form = React.createRef()

    onSubmit(values) {
        this.props.CallLogin()
        this.handleSubmit(values.email, values.password)
    }

    async handleSubmit(email, password) {
        try {
            const user = await Auth.signIn(email, password);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                message.info("Password reset required")
                this.props.ChallengeRequested(user)
                this.props.history.push('/password-reset')
            }
            else if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                message.info("MFA code required")
                this.props.ChallengeRequested(user)
                this.props.history.push('/mfa-login')
            }
            else {
                let admin = "cognito:groups" in user.signInUserSession.accessToken.payload && user.signInUserSession.accessToken.payload["cognito:groups"].includes("admin")
                Auth.currentAuthenticatedUser()
                    .then(user => this.props.Login(user, admin))
                if (admin) {
                    this.props.history.push('/admin')
                }
                else {
                    this.props.history.push('/')
                }
            }
        }
        catch (e) {
            if (e.code === 'UserNotConfirmedException') {
                message.info("Email confirmation required")
                this.props.history.push('/confirm-email?' + queryString.stringify({ email: email }))
            }
            else if (e.code === 'PasswordResetRequiredException') {
                message.error("Your account password expired, you must reset your password and accept EULA again.")
                this.props.history.push('/reset-password?' + queryString.stringify({ email: email }))
            }
            else if (e.code === 'NotAuthorizedException') {
                message.error("Wrong password")
            }
            else if (e.code === 'UserNotFoundException') {
                message.error("Wrong email")
            }
            else {
                message.error(e.message)
                console.log(e)
            }
            this.props.EndCallLogin()
        }
    }

    render() {
        const parsed = queryString.parse(this.props.location.search)
        let email = "email" in parsed ? parsed.email : ""

        if (this.props.login.fechingStatus === true) {
            return null;
        }
        else if (this.props.login.calling === false && this.props.login.auth === true) {
            return <Redirect to="/" />;
        }
        else {
            return (
                <LoginBase title="Welcome to DFDC" hideHomeButton>
                    <Form
                        hideRequiredMark={true}
                        name="login"
                        scrollToFirstError={true}
                        onFinish={(values) => this.onSubmit(values)}
                        ref={this.form}
                    >
                        <Form.Item
                            wrapperCol={
                                {
                                    xs: {
                                        span: 24,
                                        offset: 0,
                                    },
                                    sm: {
                                        span: 16,
                                        offset: 4,
                                    },
                                }
                            }
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Email required',
                                    }
                                ]
                            }
                            initialValue={email}
                            name="email"
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Email"
                                suffix={
                                    <Tooltip title="Insert your email">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="email"
                                autoFocus={email === ""}
                            />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={
                                {
                                    xs: {
                                        span: 24,
                                        offset: 0,
                                    },
                                    sm: {
                                        span: 16,
                                        offset: 4,
                                    }
                                }
                            }
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Password required',
                                    }
                                ]
                            }
                            name="password"
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="Password"
                                autoComplete="current-password"
                                type="password"
                                autoFocus={email !== ""}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.props.login.calling}
                                style={{ backgroundColor: "#8764c8", border: "None", fontWeight: "600" }}
                            >
                                Log in
                            </Button>
                        </Form.Item>
                        <Space direction="horizontal" >
                            <Link to='/sign-up'>Sign up</Link>
                            <Divider type="vertical" />
                            <Link to={"/forgot-password"}>Forgot my password</Link>
                        </Space>
                    </Form>
                </LoginBase>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
