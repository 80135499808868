export const LOGIN = (user, admin) => {
    return {
        type: "LOGIN",
        payload: user,
        admin: admin
    }
}

export const CHALLENGE_REQUESTED = (user) => {
    return {
        type: "CHALLENGE-REQUESTED",
        payload: user
    }
}

export const LOGOUT = () => {
    return {
        type: "LOGOUT"
    }
}

export const CALL = () => {
    return {
        type: "CALL"
    }
}

export const ENDCALL = () => {
    return {
        type: "ENDCALL"
    }
}