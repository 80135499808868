import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from './pages/login/Login'
import SignUp from './pages/login/SignUp'
import ConfirmEmail from './pages/login/ConfirmEmail'
import ForgotPassword from './pages/login/ForgotPassword'
import ResetPassword from './pages/login/ResetPassword'

import S3Table from './pages/user/S3Table'
import { ToS } from './pages/user/ToS'
import { DataPolicy } from './pages/user/DataPolicy'
import { Help } from './pages/user/Help'
import ChangePassword from './pages/user/ChangePassword'

import NotFound from './routes/NotFound'
// import { AdminRoute } from "./routes/Admin"
import { PrivateRoute } from "./routes/Private";

function App() {
  return (
    <Router>
      <Switch>

        {/* Login Pages */}
        <Route path='/login' component={Login} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/confirm-email' component={ConfirmEmail} />
        <Route path='/reset-password' component={ResetPassword} />
        <Route path='/forgot-password' component={ForgotPassword} />

        {/* User Pages */}
        <PrivateRoute path='/' exact component={S3Table} />
        <PrivateRoute path='/home' exact component={S3Table} />
        <PrivateRoute path='/help' exact component={Help} />
        <PrivateRoute path='/ToS' exact component={ToS} />
        <PrivateRoute path='/data-policy' exact component={DataPolicy} />
        <PrivateRoute path='/change-password' exact component={ChangePassword} />

        <PrivateRoute component={NotFound} />

      </Switch>
    </Router>
  );
}

export default App;
