import { Auth } from "aws-amplify"

const initialState = {
  login: {
    auth: false,
    calling: false,
    fetchingStatus: false,
    user: null,
    admin: null
  }
}

export const loadState = () => {
  try {
    const serializedData = localStorage.getItem('state')
    if (serializedData === null) {
      return initialState
    }
    let data = JSON.parse(serializedData)
    if ('login' in data && data["login"]["auth"]) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          data["login"]["user"] = user
          data["login"]["admin"] = "cognito:groups" in user.signInUserSession.accessToken.payload && user.signInUserSession.accessToken.payload["cognito:groups"].includes("admin")
        })
        .catch(err => {
          console.log(err)
          data = initialState
        })
    }
    return data
  } catch (error) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    let auxState = state
    let serializedData = JSON.stringify(auxState)
    localStorage.setItem('state', serializedData)
  } catch (error) {
    console.log(error)
  }
}