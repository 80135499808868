import Amplify from 'aws-amplify'

export default {
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: "us-east-1_mkb1B0oi8",
    identityPoolId: "us-east-1:d50ddc3c-887a-4f1b-a4aa-c4ff4b93dfd4",
    userPoolWebClientId: "20iag7r20tq83argr0n0mu3hvi"
  },
  Storage: {
    region: "us-east-1",
    bucket: "dfdc-data",
    identityPoolId: "us-east-1:d50ddc3c-887a-4f1b-a4aa-c4ff4b93dfd4"
  },
  API: {
    endpoints: [
      {
        name: "dfdc",
        endpoint: "https://dod1xn5sdl.execute-api.us-east-1.amazonaws.com/prod",
        region: "us-east-1",
        custom_header: async () => { return { Authorization: (await Amplify.Auth.currentSession()).getIdToken().getJwtToken() } }
      }
    ]
  }
}
