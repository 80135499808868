import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux"

function PrivateRoute({component: Component, ...rest}) {
  const login = useSelector(state => state.login)

  if (login.fechingStatus === true){
    return null;
  }
  else{
    return(
      <Route
      {...rest}
      render={props => {
        if (login.auth === true) {
          return <Component {...props} />;
        }
        else {
          return <Redirect to={{
            pathname: '/login',
            state: {
              from: props.location
            }
            }}/>
          }
        }
      } />
    )
  }
}

export {PrivateRoute};