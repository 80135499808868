import React from "react"

import { Layout, Typography, Button, message, Dropdown, Menu, Space, Divider, Modal, Input } from 'antd'
import { MenuOutlined, LogoutOutlined, LockOutlined, QuestionCircleOutlined, HomeOutlined, EditOutlined, FormOutlined } from '@ant-design/icons';
import { withRouter, Link } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'

import { connect } from "react-redux"
import { allState as mapStateToProps, allDispatch as mapDispatchToProps } from "../../redux/Selectors"

class Base extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showUpdater: false,
            loadingUpdate: false
        }
    }

    input = React.createRef()

    onMenuClick(e) {
        if (e.key === "logout") {
            this.signout()
        }
        else if (e.key === "account_number") {
            this.setState({ showUpdater: true })
        }
        else {
            this.props.history.push("/" + e.key)
        }
    }

    signout = () => {
        Auth.signOut({ global: true })
            .then(data => {
                this.props.Logout()
                this.props.history.push("/login")
            })
    }

    changeAccNumber() {
        let value = this.input.current.state.value

        if (value) {
            if (value.length === 12) {
                console.log(value)
                this.setState({ loadingUpdate: true })
                API.put(
                    'dfdc',
                    '/user-aws-account',
                    {
                        body: {
                            account_id: value
                        }
                    }
                )
                    .then(response => {
                        message.success("AWS account number updated successfully")
                        this.setState({ loadingUpdate: false, showUpdater: false })
                        Auth.currentAuthenticatedUser()
                            .then(user => {
                                this.props.Login(user, false)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    })
                    .catch(err => {
                        message.error("Invalid aws account number")
                        console.log(err)
                        this.setState({ loadingUpdate: false })
                    })
            }
            else {
                message.error("Invalid account number")
            }
        }
        else {
            message.error("Account number required")
        }
    }

    render() {
        return (
            <Layout>
                <Layout.Header>
                    <div>
                        <div style={{ textAlign: "right" }}>
                            <Dropdown
                                overlay={<Menu onClick={(e) => this.onMenuClick(e)}>
                                    <Menu.Item disabled>
                                        {this.props.login.user.attributes.email}
                                    </Menu.Item>
                                    <Menu.Item disabled>
                                        {"AWS: " + this.props.login.user.attributes["custom:account_id"]}
                                    </Menu.Item>
                                    <Menu.Item icon={<HomeOutlined />} key="home">
                                        Home
                                    </Menu.Item>
                                    <Menu.Item icon={<FormOutlined />} key="data-policy">
                                        Website data policy
                                    </Menu.Item>
                                    <Menu.Item icon={<FormOutlined />} key="ToS">
                                        Terms of service
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item icon={<QuestionCircleOutlined />} key="help">
                                        Help
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item icon={<EditOutlined />} key="account_number">
                                        Change account number
                                    </Menu.Item>
                                    <Menu.Item icon={<LockOutlined />} key="change-password">
                                        Change password
                                    </Menu.Item>
                                    <Menu.Item icon={<LogoutOutlined />} key="logout">
                                        Log out
                                    </Menu.Item>
                                </Menu>}
                            >
                                <Button style={{ margin: "10px" }}>
                                    <MenuOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </Layout.Header>
                <Layout.Content
                    style={{ padding: "10px" }}
                >
                    <Typography.Title style={{ textAlign: "center" }}>{this.props.title}</Typography.Title>
                    <Typography.Title level={2}>{this.props.subtitle}</Typography.Title>
                    {this.props.children}
                </Layout.Content>
                <Layout.Footer>
                    <Space direction="horizontal" >
                        <Link to='/'>Home</Link>
                        <Divider type="vertical" />
                        <Link to='/ToS'>Terms of service</Link>
                        <Divider type="vertical" />
                        <Link to={"/data-policy"}>Website data policy</Link>
                    </Space>
                </Layout.Footer>
                <Modal
                    visible={this.state.showUpdater}
                    title="Change aws account number"
                    okText="Change account number"
                    onCancel={() => this.setState({ showUpdater: false })}
                    onOk={() => this.changeAccNumber()}
                    okButtonProps={{ loading: this.state.loadingUpdate }}
                >
                    <Input
                        placeholder="New aws account number"
                        type="number"
                        ref={this.input}
                    />
                </Modal>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Base));