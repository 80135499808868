import { LOGIN, CALL, ENDCALL, LOGOUT, CHALLENGE_REQUESTED } from "../Actions"

export const allState = state => {
    return {
        menuCollapsed: state.menu,
        login: state.login
    }
}

export const allDispatch = dispatch => {
    return {
        Login: (user, admin) => dispatch(LOGIN(user, admin)),
        CallLogin: () => dispatch(CALL()),
        EndCallLogin: () => dispatch(ENDCALL()),
        Logout: () => dispatch(LOGOUT()),
        ChallengeRequested: () => dispatch(CHALLENGE_REQUESTED())
    }
}


export const loginState = state => {
    return {
        login: state.login
    }
}

export const loginDispatch = dispatch => {
    return {
        Login: (user, admin) => dispatch(LOGIN(user, admin)),
        CallLogin: () => dispatch(CALL()),
        EndCallLogin: () => dispatch(ENDCALL()),
        Logout: () => dispatch(LOGOUT()),
        ChallengeRequested: () => dispatch(CHALLENGE_REQUESTED())
    }
}