import { createStore } from "redux"
import allReducers from "./Reducers"
import { loadState, saveState } from "./localStorage"

const initialData = loadState()

export const store = createStore(
    allReducers,
    initialData
);

store.subscribe(() => {
    saveState(store.getState())
})