const initialState = {
    auth: false,
    calling: false,
    fetchingStatus: false,
    user: null,
    admin: null
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN":
            return {fetchingStatus: false, calling: false, auth: true, user: action.payload, admin: action.admin}
        case "LOGOUT":
            return {...state, auth: false, user: null, admin: null}
        case "CALL":
            return {...state, calling: true}
        case "ENDCALL":
            return {...state, calling: false}
        case "CHALLENGE-REQUESTED":
            return {...state, user: action.payload, calling: false, auth: false}
        case "UPDATE-USER":
            return {...state, user: action.payload, admin: action.admin}
        default:
            return state
    }
}

export default loginReducer;