import React from "react"

import { connect } from "react-redux"
import { Redirect, withRouter, Link } from "react-router-dom"
import { Form, Input, Button, Tooltip, message } from 'antd'
import { MailOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify"

import LoginBase from './LoginBase'
import { allState as mapStateToProps, allDispatch as mapDispatchToProps } from "../../redux/Selectors"

const queryString = require('query-string');

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    async onSubmit(values) {
        this.setState({ loading: true })
        Auth.forgotPassword(
            values.email
        )
            .then(data => {
                message.success("Verification code sent to your email")
                this.props.history.push('/reset-password?' + queryString.stringify({ email: values.email }))
            })
            .catch(error => {
                message.error(error.message)
                this.setState({ loading: false })
            })
    }

    render() {

        const parsed = queryString.parse(this.props.location.search)
        let email = "email" in parsed ? parsed.email : ""

        if (this.props.login.fechingStatus === true) {
            return null;
        }
        else if (this.props.login.calling === false && this.props.login.auth === true) {
            return <Redirect to="/" />;
        }
        else {
            return (
                <LoginBase title="Reset password">
                    <Form
                        onFinish={(values) => this.onSubmit(values)}
                        hideRequiredMark={true}
                        style={{ margin: "10px" }}
                    >
                        <Form.Item
                            name='email'
                            rules={
                                [
                                    {
                                        type: 'email',
                                        message: 'Valid email required',
                                    },
                                    {
                                        required: true,
                                        message: 'Email required',
                                    },
                                ]
                            }
                            initialValue={email}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="E-mail"
                                suffix={
                                    <Tooltip title="Fill in with your email">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="email"
                                autoFocus={email === ""}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.props.login.calling || this.state.loading}
                                autoFocus={email !== ""}
                            >
                                Send verification code
                            </Button>
                        </Form.Item>
                        <Link to='/reset-password'>I have a verification code</Link>
                    </Form>
                </LoginBase>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
