import React from "react"

import { Typography, Card } from 'antd'
import { CodeBlock } from 'react-code-blocks'

import Base from './Base'

const { Paragraph, Title, Text } = Typography

export class Help extends React.Component {
    render() {
        return (
            <Base
                title="Download instructions"
            >
                <Card>
                    <Title level={3}>Prerequisites</Title>
                    <Paragraph>
                        In order to access the datasets, each user <Text strong>must</Text> have an AWS account with
                        an <Text strong>IAM user and Access Keys</Text> setup. Each user must also have an AWS Account number
                        ready in order to sign up and access the datasets.
                    </Paragraph>
                    <ul>
                        <li>
                            <a href="https://portal.aws.amazon.com/billing/signup?type=enterprise#/start" target="_blank" rel="noopener noreferrer">Create an AWS Account</a>
                        </li>
                        <li>
                            <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_users_create.html" target="_blank" rel="noopener noreferrer">Create an AWS IAM User</a>
                        </li>
                    </ul>
                    <Paragraph>
                        Once you have created an IAM User, save or download the credentials which includes
                        an <Text code>ACCESS KEY</Text> and a <Text code>SECRET KEY</Text>. These are your API keys. Keep these in a secure location.
                    </Paragraph>
                    <ul>
                        <li>
                            Download and Configure the AWS CLI.
                            <a href="https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-configure.html" target="_blank" rel="noopener noreferrer"> Instructions to download and install for your OS.</a>
                        </li>
                    </ul>
                    <Paragraph>
                        <Text strong>NOTE:</Text> it is recommended to configure the CLI as a named profile.
                            In the following <Text underline>example</Text> we will configure and leverage a profile named <Text code>"dfdc"</Text>:
                        </Paragraph>
                    <ul>
                        <li>
                            In a terminal or command prompt, run: <Text code>aws configure --profile dfdc</Text>. This will create an entry in
                            your <Text code>~/.aws/credentials</Text> file under the <Text code>[dfdc]</Text> heading.<br /><br />
                            <CodeBlock
                                text={
                                    `$ aws configure --profile dfdc
$ AWS Access Key ID [None]: 123456789
$ AWS Secret Access Key [None]: 987654321
$ Default region name [None]: us-east-1
$ Default output format [None]:`
                                }
                                showLineNumbers={false}
                                language="bash"
                            /><br />
                        </li>
                        <li>
                            Validate your credentials. This will be differ by user but an easy way to do this AND
                                collect the account information for the next step. Run: <Text code>aws sts get-caller-identity --profile dfdc</Text>
                            . This will list the UserId, Account Number, and arn of your user:
                                <CodeBlock
                                text={`{
    "UserId": "EXAMPLEUSERID12345",
    "Account": "123456789012",
    "Arn": "arn:aws:iam::123456789012:user/username"
}`}
                                language="json"
                                showLineNumbers={false}
                            /><br />
                        </li>
                        <li>
                            Sign up for and login to https://dfdc.ai and select a file that is available for download to generate a per-user download command. This allows you to see your users specific s3 download url.
                            For example, by selecting the validation folder and labels.csv checkbox (GUI in the browser), the following string is returned:
                            <CodeBlock
                                text="aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/validation/labels.csv . --request-payer --region=us-east-1"
                                showLineNumbers={false}
                                language="bash"
                            />
                            This is an <Text strong>EXAMPLE</Text> command. You can dissect the URL in multiple parts:
                            <ol>
                                <li>
                                    <Text code>aws</Text>: Calls the AWS CLI
                                </li>
                                <li>
                                    <Text code>s3</Text> Calls the s3 service
                                </li>
                                <li>
                                    <Text code>s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/</Text>: The source data which is the ARN of the s3 bucket
                                </li>
                                <li>
                                    <Text code>.</Text>: The destination which is the current folder. This can be changed to whatever you want
                                </li>
                                <li>
                                    <Text code>--request-payer</Text>: Instructs AWS to charge the destination AWS account for data transfer charges
                                </li>
                                <li>
                                    <Text code>--region=us-east-1</Text>: The AWS Region the dataset is located in
                                </li>
                            </ol><br />
                            <Card>
                                <Text strong>NOTE: </Text>The <Text code>--profile dfdc</Text> option is not present as this will vary from user to user depending on your setup. Howevever, you will get an error message if you do not pass the --profile argument with valid credentials:
                                <CodeBlock
                                    text="fatal error: Unable to locate credentials"
                                    showLineNumbers={false}
                                    language="bash"
                                />
                            </Card><br />
                        </li>
                        <li>
                            For example, you can list all of the contents of the bucket with:<br /><Text code>aws s3 ls s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/ --request-payer --profile dfdc</Text>
                            . Expected output:
                            <CodeBlock
                                text={`PRE test/
PRE train/
PRE validation/`}
                                showLineNumbers={false}
                                language="bash"
                            /><br />
                        </li>
                        <li>
                            Back on https://dfdc.ai you can also copy individual objects or sync the contents of each folder. Select a single object and you will have a cp (copy) command generated for you you:
                            <CodeBlock
                                text={`aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/test/0.zip . --request-payer --region=us-east-1
aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/test/1.zip . --request-payer --region=us-east-1
aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/test/2.zip . --request-payer --region=us-east-1
aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/test/3.zip . --request-payer --region=us-east-1
aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/test/4.zip . --request-payer --region=us-east-1`}
                                showLineNumbers={false}
                                language="bash"
                            /><br/>
                        </li>
                        <li>
                            Select the "Select All" button to generate a sync command:
                            <CodeBlock
                                text="aws s3 sync s3://arn:aws:s3:us-east-1:604877620213:accesspoint/dfdc-data-ap-0/test . --request-payer --region=us-east-1"
                                showLineNumbers={false}
                                language="bash"
                            /><br/>
                        </li>
                        <li>
                        You can copy and paste from the browser select the DOWNLOAD button. This will NOT download the objects. It will download a file with the generated copy or sync commands.
                        </li>
                    </ul>
                </Card>
            </Base>
        )
    }
}