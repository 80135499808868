import React from "react"

import { Layout, Row, Col, Card, Typography, Affix, Button } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'

const { Content } = Layout;
const { Title } = Typography;

class LoginBase extends React.Component {

    render() {
        return(
            <Layout>
                <Content
                    style={{
                        background: "linear-gradient(rgb(99, 51, 162), rgb(28, 111, 175))"
                    }}
                >
                    {
                        !this.props.hideHomeButton &&
                            <Affix
                                offsetTop={15}
                                style={{marginLeft: '15px'}}
                            >
                                <Button onClick={(e) => this.props.history.push('/login')} >
                                    <HomeOutlined />
                                </Button>
                            </Affix>
                    }
                    <Row
                        type="flex"
                        justify="space-around"
                        align="middle"
                        style={{
                            height:"100vh"
                        }}
                    >
                        <Col
                            xs={22}
                            sm={20}
                            lg={12}
                        >
                            <Card
                                style={{
                                    borderRadius:"0",
                                    height:"auto",
                                    textAlign:"center",
                                    marginTop:"5px",
                                    paddingTop: "40px",
                                    paddingBottom: "20px"
                                }}
                            >
                                <Title
                                    level={2}
                                    style={{
                                        marginBottom:"40px"
                                    }}
                                >
                                    <b>
                                        {this.props.title}
                                    </b>
                                </Title>
                                {this.props.children}
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

export default withRouter(LoginBase);