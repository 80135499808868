import React from "react"
import { Redirect } from "react-router-dom"

class NotFound extends React.Component {

    render() {
        return(
            <Redirect to="/" />
        );
    }
}

export default NotFound;