import React from "react"

import { Auth, Storage } from 'aws-amplify';
import { Table, Card, Typography, Button, Tabs, Tooltip, message, Input, Space, Alert, Row, Col } from 'antd';
import { CopyOutlined, DownloadOutlined, FolderOutlined, SearchOutlined, PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { saveAs } from 'file-saver';
import Highlighter from 'react-highlight-words'
import { withRouter, Link } from "react-router-dom"
import moment from "moment";

import { connect } from "react-redux"
import { allState as mapStateToProps, allDispatch as mapDispatchToProps } from "../../redux/Selectors"

import config from '../../util/config'
import Base from './Base'

class S3Table extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            files: [],
            selected: [],
            folder: config.folders[0],
            search: '',
            userData: null,
            loadingUser: true
        }
    }

    input = React.createRef()

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                this.getFiles(this.state.folder)
                Auth.userAttributes(user)
                    .then(attr => this.setState({ userData: attr, loadingUser: false }))
                    .catch(console.log)
            })
            .catch(err => {
                console.log(err)
                this.props.Logout()
            })
    }

    getFiles(folder) {
        let conf = {
            customPrefix: {
                public: ""
            }
        }

        Storage.list(folder, conf)
            .then((result) => {
                result = result.filter(value => value.key !== folder + "/")
                result = result.map(value => {
                    value["key"] = value["key"].replace(folder + "/", "")
                    return value
                })
                this.setState({ files: result, loading: false })
            })
            .catch(console.log)
    }

    downloadScript(name, content) {
        var blob = new Blob([content], { type: "text/plain;charset=utf-8" })
        saveAs(blob, name)
    }

    selectedFiles(selectedRows) {
        this.setState({ selected: selectedRows })
    }

    humanFileSize(size) {
        if (size === 0) return 0 + " B"
        var i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    handleSearch(selectedKeys, confirm) {
        confirm()
        this.setState({
            search: selectedKeys[0]
        })
    }

    handleReset(clearFilters) {
        clearFilters()
        this.setState({ search: '' })
    }

    selectRow(record) {
        const selected = [...this.state.selected]
        if (selected.filter(value => value.key === record.key).length !== 0) {
            selected.splice(selected.indexOf(selected.filter(value => value.key === record.key)[0]), 1)
        }
        else {
            selected.push(record)
        }
        this.selectedFiles(selected)
    }

    render() {
        if (!this.state.userData) return <Base title="DFDC data set" />
        let key = this.state.userData.filter(attr => attr.Name === "custom:key")[0].Value

        let columns = [
            {
                title: 'File name',
                dataIndex: 'key',
                key: 'key',
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={node => {
                                this.searchInput = node;
                            }}
                            placeholder="Search by file name"
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button.Group>
                                <Button
                                    type="primary"
                                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                                    icon={<SearchOutlined />}
                                    size="small"
                                    style={{ width: 94 }}
                                >
                                    Search
                            </Button>
                                <Button
                                    onClick={() => this.handleReset(clearFilters)}
                                    style={{ width: 94 }}
                                    size="small"
                                >
                                    Reset
                            </Button>
                            </Button.Group>
                        </Space>
                    </div>
                ),
                filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
                onFilter: (value, record) => record.key.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownVisibleChange: visible => {
                    if (visible) setTimeout(() => this.searchInput.select())
                },
                render: value => {
                    if (this.state.search !== '') {
                        return (
                            <Highlighter
                                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                                searchWords={[this.state.search]}
                                autoEscape
                                textToHighlight={value.toString()}
                            />
                        )
                    }
                    else {
                        return value
                    }
                }
            },
            {
                title: 'Size',
                dataIndex: 'size',
                key: 'size',
                render: (value) => this.humanFileSize(value),
                sorter: (a, b) => a.size - b.size
            }
        ]

        let text = this.state.selected.length !== this.state.files.length ?
            this.state.selected.reduce((acc, curr) => acc + "aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/" + key + "/" + this.state.folder + "/" + curr.key + " . --request-payer --region=us-east-1\n", "")
            :
            ["aws s3 sync s3://arn:aws:s3:us-east-1:604877620213:accesspoint/" + key + "/" + this.state.folder + " . --request-payer --region=us-east-1"]
        let fileName = "dfdc-download-script" + moment().format("_YYYY_MM_DD") + ".sh"

        return (
            <Base
                title="DFDC data set"
            >
                <Row>
                    <Col xs={24}>
                        <Alert
                            message={<Link to="/help">Instructions to use this tool</Link>}
                            type="info"
                            showIcon
                            closable
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <Card
                            style={{ margin: "10px" }}
                            title="Files available for download"
                            extra={
                                <Button.Group>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.selectedFiles(this.state.files)
                                        }}
                                        type="primary"
                                        disabled={this.state.selected.length === this.state.files.length}
                                    >
                                        <PlusSquareOutlined />
                                        Select all
                                    </Button>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.selectedFiles([])
                                        }}
                                        type="danger"
                                        disabled={this.state.selected.length === 0}
                                    >
                                        <MinusSquareOutlined />
                                        Remove selection
                                    </Button>
                                </Button.Group>
                            }
                        >
                            <Tabs
                                activeTabKey={this.state.folder}
                                onTabClick={(key) => {
                                    this.setState({
                                        folder: key,
                                        selected: [],
                                        files: [],
                                        loading: true
                                    })
                                    this.getFiles(key)
                                }}
                            >
                                {
                                    config.folders.map(value => {
                                        return (
                                            <Tabs.TabPane
                                                key={value}
                                                tab={
                                                    <span>
                                                        <FolderOutlined />
                                                        {value}
                                                    </span>
                                                }
                                            >
                                                <Table
                                                    dataSource={this.state.files}
                                                    columns={columns}
                                                    loading={this.state.loading}
                                                    pagination={{
                                                        defaultPageSize: 5,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['5', '10', '20'],
                                                        hideOnSinglePage: true
                                                    }}
                                                    rowSelection={{
                                                        type: "checkbox",
                                                        onChange: (selectedRowKeys, selectedRows) => this.selectedFiles(selectedRows),
                                                        selections: true,
                                                        selectedRowKeys: this.state.selected.map(value => value["key"])
                                                    }}
                                                    onRow={(record) => ({
                                                        onClick: () => this.selectRow(record)
                                                    })}
                                                />
                                            </Tabs.TabPane>
                                        )
                                    })
                                }
                            </Tabs>
                        </Card>
                    </Col>
                    <Col xs={24} xxl={12}>
                        <Card
                            style={{ margin: "10px" }}
                            title="Download with aws cli"
                            extra={
                                <Button.Group>
                                    <CopyToClipboard
                                        text={text}
                                        onCopy={() => text !== "" && message.success("Script saved in clipboard")}
                                    >
                                        <Tooltip title="Copy script to clipboard">
                                            <Button disabled={text === ""} type="primary">
                                                <CopyOutlined />
                                                Copy
                                            </Button>
                                        </Tooltip>
                                    </CopyToClipboard>
                                    <Tooltip title="Download script">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.downloadScript(fileName, text)
                                            }}
                                            disabled={text === ""}
                                            type="primary"
                                        >
                                            <DownloadOutlined />
                                            Download
                                        </Button>
                                    </Tooltip>
                                </Button.Group>
                            }
                        >
                            {
                                this.state.selected.length !== 0 ?
                                    this.state.selected.length !== this.state.files.length ?
                                        this.state.selected.map(value => <Typography.Paragraph key={value.key}>{"aws s3 cp s3://arn:aws:s3:us-east-1:604877620213:accesspoint/" + key + "/" + this.state.folder + "/" + value.key + " . --request-payer --region=us-east-1\n"}</Typography.Paragraph>)
                                        :
                                        ["aws s3 sync s3://arn:aws:s3:us-east-1:604877620213:accesspoint/" + key + "/" + this.state.folder + " . --request-payer --region=us-east-1"]
                                    :
                                    <Alert
                                        message="Select one or more files to generate a sample script"
                                        description="We will generate a secure script for you to run in your terminal. Script will be generated only for selected files."
                                        type="info"
                                        showIcon
                                    />
                            }
                        </Card>
                    </Col>
                </Row>
            </Base>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(S3Table))