import React from "react";

import { Redirect, withRouter } from "react-router-dom"
import { Form, Input, Button, Tooltip, message, Checkbox, Modal, Select, Typography } from 'antd'
import { MailOutlined, InfoCircleOutlined, LockOutlined, NumberOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify"
import { CodeBlock } from 'react-code-blocks'
import { connect } from "react-redux"
import { allState as mapStateToProps, allDispatch as mapDispatchToProps } from "../../redux/Selectors"

import LoginBase from './LoginBase'
import { eula } from '../../util/eula'
import config from '../../util/config'

const { Text, Paragraph } = Typography
const queryString = require('query-string')

class SignUp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showEULA: false,
            readEULA: false,
            showHelp: false
        }
    }

    form = React.createRef()

    async onSubmit(values) {
        this.setState({ loading: true })

        let data = {
            username: values.email,
            password: values.password,
            attributes: {
                "custom:EULA": values.EULA ? "true" : "false",
                "custom:account_id": values.account_id,
                given_name: values.first_name,
                family_name: values.last_name,
                "custom:organization": values.organization,
                "custom:intended_use": values.intended_use,
                "custom:location": values.location
            }
        }
        Auth.signUp(data)
            .then(data => {
                message.success("Account successfully created, please confirm your email")
                this.props.history.push('/confirm-email?' + queryString.stringify({ email: values.email }))
            })
            .catch(error => {
                if (error.code === "UserLambdaValidationException") {
                    this.form.current.setFields([{ name: "account_id", errors: ["Invalid aws account ID"] }])
                }
                else {
                    console.log(error)
                    message.error(error.message)
                }
                this.setState({ loading: false })
            })
    }

    compareToFirstPassword = (rule, value) => {
        if (value && value !== this.form.current.getFieldValue('password')) {
            return Promise.reject('The passwords do not match');
        } else {
            return Promise.resolve();
        }
    };

    validateToNextPassword = (rule, value) => {
        if (value) {
            this.form.current.validateFields(['confirm_password'], { force: true });
        }
        return Promise.resolve()
    };

    checkEULA = (rule, value) => {
        if (!value) {
            return Promise.reject('You must accept EULA to sign up')
        }
        else {
            return Promise.resolve()
        }
    }

    render() {
        if (this.props.login.fechingStatus === true) {
            return null;
        }
        else if (this.props.login.calling === false && this.props.login.auth === true) {
            return <Redirect to="/" />;
        }
        else {

            return (
                <LoginBase title="Sign up">
                    <Form
                        onFinish={(values) => this.onSubmit(values)}
                        hideRequiredMark={true}
                        style={{ margin: "10px" }}
                        ref={this.form}
                        scrollToFirstError
                    >
                        <Form.Item
                            rules={
                                [
                                    {
                                        type: 'email',
                                        message: 'Valid email required',
                                    },
                                    {
                                        required: true,
                                        message: 'Email required',
                                    },
                                ]
                            }
                            name="email"
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="E-mail"
                                suffix={
                                    <Tooltip title="Complete with your email">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="username"
                                autoFocus
                            />
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'First name required',
                                    }
                                ]
                            }
                            name="first_name"
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="First name"
                                suffix={
                                    <Tooltip title="Complete with your first name">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="given-name"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Last name required',
                                    }
                                ]
                            }
                            name="last_name"
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Last name"
                                suffix={
                                    <Tooltip title="Complete with your last name">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="family-name"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Organization name required',
                                    }
                                ]
                            }
                            name="organization"
                        >
                            <Input
                                prefix={<HomeOutlined />}
                                placeholder="Organization"
                                suffix={
                                    <Tooltip title="Complete with the organization you belong to">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="organization"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Location required',
                                    }
                                ]
                            }
                            name="location"
                        >
                            <Select
                                placeholder="Location"
                                style={{ textAlign: "left" }}
                                autoComplete="country-name"
                            >
                                {
                                    config.countries.map(country => {
                                        return <Select.Option value={country} key={country}>{country}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Intended use required',
                                    }
                                ]
                            }
                            name="intended_use"
                        >
                            <Select
                                placeholder="Intended use"
                                style={{ textAlign: "left" }}
                            >
                                {
                                    config.intended_use_options.map(option => {
                                        return <Select.Option value={option.Value} key={option.Value}>{option.Name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'AWS account number required',
                                    },
                                    {
                                        len: 12,
                                        message: "Invalid aws account ID length"
                                    }
                                ]
                            }
                            name="account_id"
                        >
                            <Input
                                type="number"
                                prefix={<NumberOutlined />}
                                placeholder="AWS account number"
                                suffix={
                                    <Tooltip
                                        title="AWS own account number required to download files. Click for more information on this."
                                        onClick={() => this.setState({ showHelp: true })}
                                    >
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Password required',
                                    },
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            }
                            name="password"
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="New password"
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Password confirmation required',
                                    },
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ]
                            }
                            name='confirm_password'
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="Password confirmation"
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="EULA"
                            valuePropName="checked"
                            trigger={null}
                            rules={
                                [
                                    {
                                        validator: this.checkEULA
                                    }
                                ]
                            }
                        >
                            <Checkbox
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.setState({ showEULA: true })
                                }}
                            >
                                Review and accept terms and conditions
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.loading}
                                style={{
                                    marginTop: "20px"
                                }}
                            >
                                Sign up
                            </Button>
                        </Form.Item>
                        <Modal
                            title="Terms and conditions"
                            visible={this.state.showEULA}
                            centered
                            okText="Accept"
                            okButtonProps={{
                                disabled: !this.state.readEULA
                            }}
                            cancelText="Decline"
                            maskClosable={false}
                            closable={false}
                            onCancel={() => {
                                this.setState({ showEULA: false })
                                this.form.current.setFieldsValue({ "EULA": false })
                            }}
                            onOk={() => {
                                this.setState({ showEULA: false })
                                this.form.current.setFieldsValue({ "EULA": true })
                            }}
                        >
                            <Input.TextArea
                                style={{
                                    resize: "none"
                                }}
                                value={eula}
                                autoSize={false}
                                readOnly
                                rows="15"
                                autoFocus
                                onScroll={(e) => this.setState({ readEULA: e.target.scrollHeight - e.target.offsetHeight < e.target.scrollTop })}
                            />
                        </Modal>
                        <Modal
                            title="AWS account requirement"
                            visible={this.state.showHelp}
                            centered
                            okText="Ok"
                            cancelButtonProps={{
                                hidden: true
                            }}
                            maskClosable={false}
                            closable={false}
                            onCancel={() => this.setState({ showHelp: false })}
                            onOk={() => this.setState({ showHelp: false })}
                        >
                            <Paragraph>In order to access the datasets, each user <Text strong>must</Text> have an AWS account with
                            an <Text strong>IAM user and Access Keys</Text> setup. Each user must also have an AWS Account number
                            ready in order to sign up and access the datasets.</Paragraph>
                            <ul>
                                <li>
                                    <a href="https://portal.aws.amazon.com/billing/signup?type=enterprise#/start" target="_blank" rel="noopener noreferrer">Create an AWS Account</a>
                                </li>
                                <li>
                                    <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_users_create.html" target="_blank" rel="noopener noreferrer">Create an AWS IAM User</a>
                                </li>
                            </ul>
                            <Paragraph>
                                Once you have created an IAM User, save or download the credentials which includes
                                an <Text code>ACCESS KEY</Text> and a <Text code>SECRET KEY</Text>. These are your API keys. Keep these in a secure location.
                            </Paragraph>
                            <ul>
                                <li>
                                    Download and Configure the AWS CLI.
                            <a href="https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-configure.html" target="_blank" rel="noopener noreferrer"> Instructions to download and install for your OS.</a>
                                </li>
                            </ul>
                            <Paragraph>
                                <Text strong>NOTE:</Text> it is recommended to configure the CLI as a named profile.
                            In the following <Text underline>example</Text> we will configure and leverage a profile named <Text code>"dfdc"</Text>:
                        </Paragraph>
                            <ul>
                                <li>
                                    In a terminal or command prompt, run: <Text code>aws configure --profile dfdc</Text>. This will create an entry in
                            your <Text code>~/.aws/credentials</Text> file under the <Text code>[dfdc]</Text> heading.<br /><br />
                                    <CodeBlock
                                        text={
                                            `$ aws configure --profile dfdc
$ AWS Access Key ID [None]: 123456789
$ AWS Secret Access Key [None]: 987654321
$ Default region name [None]: us-east-1
$ Default output format [None]:`
                                        }
                                        showLineNumbers={false}
                                        language="bash"
                                    /><br />
                                </li>
                                <li>
                                    Validate your credentials. This will be differ by user but an easy way to do this AND
                                collect the account information for the next step. Run: <Text code>aws sts get-caller-identity --profile dfdc</Text>
                                    . This will list the UserId, Account Number, and arn of your user:
                                <CodeBlock
                                        text={`{
    "UserId": "EXAMPLEUSERID12345",
    "Account": "123456789012",
    "Arn": "arn:aws:iam::123456789012:user/username"
}`}
                                        language="json"
                                        showLineNumbers={false}
                                    /><br />
                                </li>
                                <li>
                                    Sign up for and login to https://dfdc.ai using that account number
                                </li>
                            </ul>
                        </Modal>
                    </Form>
                </LoginBase>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp));
