import React from "react"

import { Redirect, withRouter, Link  } from "react-router-dom"
import { Form, Input, Button, Tooltip, message, Checkbox, Modal } from 'antd'
import { UnlockOutlined, InfoCircleOutlined, LockOutlined, MailOutlined } from "@ant-design/icons"
import { Auth } from "aws-amplify"
import { connect } from "react-redux"

import { allState as mapStateToProps, allDispatch as mapDispatchToProps } from "../../redux/Selectors"
import LoginBase from './LoginBase'

import { eula } from '../../util/eula'

const queryString = require('query-string');

class ResetPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showEULA: false,
            readEULA: false
        }
    }

    async onSubmit(values) {
        this.setState({ loading: true })
        Auth.forgotPasswordSubmit(
            values.username,
            values.confirmation_code,
            values.new_password
        )
            .then(data => {
                message.success("Password changed successfully")
                this.props.history.push('/login?' + queryString.stringify({ email: values.username }))
            })
            .catch(error => {
                message.error(error.message)
                this.setState({ loading: false })
            })
    }

    form = React.createRef()

    compareToFirstPassword = (rule, value) => {
        if (value && value !== this.form.current.getFieldValue('new_password')) {
            return Promise.reject('Passwords do not match');
        } else {
            return Promise.resolve()
        }
    };

    validateToNextPassword = (rule, value) => {
        if (value) {
            this.form.current.validateFields(['confirm_password'], { force: true });
        }
        return Promise.resolve()
    };

    checkEULA = (rule, value) => {
        if (!value) {
            return Promise.reject('You must accept EULA to reset password')
        }
        else {
            return Promise.resolve()
        }
    }

    render() {
        const parsed = queryString.parse(this.props.location.search)
        let email = "email" in parsed ? parsed.email : ""

        if (this.props.login.fechingStatus === true) {
            return null;
        }
        else if (this.props.login.calling === false && this.props.login.auth === true) {
            return <Redirect to="/" />;
        }
        else {
            return (
                <LoginBase title="Reset password and review EULA">
                    <Form
                        onFinish={(values) => this.onSubmit(values)}
                        hideRequiredMark={true}
                        style={{ margin: "10px" }}
                        ref={this.form}
                    >
                        <Form.Item
                            name='username'
                            rules={
                                [
                                    {
                                        type: 'email',
                                        message: 'Email válido requerido!',
                                    },
                                    {
                                        required: true,
                                        message: 'Email requerido',
                                    },
                                ]
                            }
                            initialValue={email}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="E-mail"
                                suffix={
                                    <Tooltip title="Complete with your email">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="username"
                                autoFocus={email === ""}
                            />
                        </Form.Item>
                        <Form.Item
                            name='confirmation_code'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Verification code required',
                                    },
                                ]
                            }
                        >
                            <Input
                                prefix={<UnlockOutlined />}
                                placeholder="Verification code"
                                suffix={
                                    <Tooltip title="Complete with the verification code sent to your email">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                autoComplete="off"
                                autoFocus={email !== ""}
                            />
                        </Form.Item>
                        <Form.Item
                            name='new_password'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'New password required',
                                    },
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            }
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="New password"
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item
                            name='confirm_password'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Password confirm required',
                                    },
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ]
                            }
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="Password confirm"
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="EULA"
                            valuePropName="checked"
                            trigger={null}
                            rules={
                                [
                                    {
                                        validator: this.checkEULA
                                    }
                                ]
                            }
                        >
                            <Checkbox
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.setState({ showEULA: true })
                                }}
                            >
                                Review and accept terms and conditions
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.props.login.calling || this.state.loading}
                            >
                                Change password
                            </Button>
                        </Form.Item>
                        <Modal
                            title="Terms and conditions"
                            visible={this.state.showEULA}
                            centered
                            okText="Accept"
                            okButtonProps={{
                                disabled: !this.state.readEULA
                            }}
                            cancelText="Decline"
                            maskClosable={false}
                            closable={false}
                            onCancel={() => {
                                this.setState({ showEULA: false })
                                this.form.current.setFieldsValue({ "EULA": false })
                            }}
                            onOk={() => {
                                this.setState({ showEULA: false })
                                this.form.current.setFieldsValue({ "EULA": true })
                            }}
                        >
                            <Input.TextArea
                                style={{
                                    resize: "none"
                                }}
                                value={eula}
                                autoSize={false}
                                readOnly
                                rows="15"
                                autoFocus
                                onScroll={(e) => this.setState({ readEULA: e.target.scrollHeight - e.target.offsetHeight < e.target.scrollTop })}
                            />
                        </Modal>
                        <Link to='/forgot-password'>I didn't receive a verification code</Link>
                    </Form>
                </LoginBase>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
